<template>
    <div>
    <router-view></router-view>
  </div>
</template>

<script>

export default {
  
};
</script>

<style>
@import 'styles/styles.css';
@import 'sweetalert2/dist/sweetalert2.min.css';
</style>
