import { createRouter, createWebHistory } from 'vue-router';

import Llama from '@/views/HomeViewLlama.vue';


const routes = [
  {
    path: '/',
    name: 'Home',
    component: Llama,
  }
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

export default router;