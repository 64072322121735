<template>
  <div class="container">
    <div class="chat-container" :hidden="!chat_hidden" @scroll="scroll_chat">
      <div v-for="(message, index) in messages" :key="index" class="message">
        <template v-if="message.role === 'user' && isNonEmptyString(message.content)">
          <div class="label">Question:</div>
          <div class="user-message">{{ message.content }}</div>
        </template>
        <template v-if="message.role === 'assistant' && isNonEmptyString(message.content)">
          <div class="label">Answer:</div>
          <div class="bot-message">{{ message.content }}</div>
          <div class="bot-sources">Sources:
            <div v-for="(source, index) in message.sources" :key="index" class="">
              <template v-if="isNonEmptyString(source.source)">
                {{ source.source }}
              </template>
            </div>
          </div>
        </template>
      </div>
    </div>
    <div class="input-container-wraper">
      <button @click="cancel" class="btn" :hidden="!loading" id="btncancel">Stop generating</button>
      <div class="input-container">
        <textarea
          v-model="input_text"
          ref="input_text"
          id="question"
          :style="style_enable"
          placeholder="Send a message"
          :hidden="loading"
          @scroll="scroll"
          v-on:input="check"
        ></textarea>
        <div v-if="loading" class="center">
          <div class="loading-spinner"></div>
        </div>
        <button
          @click="send"
          class="button-corner"
          :disabled="!send_enable"
          id="btnsend"
          :hidden="loading"
        >Send</button>
      </div>
    </div>
    <div class="help is-danger">{{ instruction }}</div>
  </div>
</template>

<script>

export default {
  data() {
    return {
      loading: false,
      send_enable: false,
      chat_hidden: false,
      style_enable: "margin-bottom: 0px;",
      input_text: '',
      messages: [],
      limit: 5000,
    };
  },
  computed: {
    instruction() {
      return this.limit - this.input_text.length === 0
        ? 'limit is ' + this.limit + ' characters'
        : '';
    }
  },
  methods: {
    async send() {
      if (this.input_text !== "") {
        this.loading = true;
        const mes = {
          role: 'user',
          content: this.input_text
        };
        this.messages.push(mes);
        this.messages.push({
                    role: 'assistant',
                    content: "...",
                    sources: ""
                  });

        try {
          const response = await fetch('https://chat.kamenit.rs/rag/api/chat', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
              model: 'llama3.1',
              messages: [
                ...this.messages,
                mes
              ],
              stream: true
            })
          });

          if (!response.ok) throw new Error('Network response was not ok');
          

          const reader = response.body.getReader();
          const decoder = new TextDecoder();

          // Process streamed data
          var curentmes="";
          
          for await (const chunk of this.streamAsyncIterator(reader, decoder)) {
            this.loading = false;
          this.input_text = "";
          this.chat_hidden = true;
            const parts = chunk.split('\n');  // Assuming messages are separated by newlines
            parts.forEach(part => {
              if (part.trim()) {
                try {

                  const data = JSON.parse(part);
                  curentmes+=data.message.content
                  var sources=data.message.sources
                  this.messages[this.messages.length - 1] = {
                      role: 'assistant',
                      content: curentmes,
                      sources: sources
                    };                  

                } catch (e) {
                  console.error('Error parsing message:', e);
                }
              }
            });
            this.$nextTick(() => {
              this.scroll_chat();
            });
          }
          // await sleep(100);
          
          this.input_text = "";
        } catch (error) {
          this.$swal(error.message || 'An error occurred');
        } finally {
          this.loading = false;
          this.chat_hidden = true;
        }
      }
    },
    async *streamAsyncIterator(reader, decoder) {
      let buffer = '';

      while (true) {
        const { done, value } = await reader.read();
        if (done) break;

        buffer += decoder.decode(value, { stream: true });

        let parts = buffer.split('\n');
        buffer = parts.pop();

        for (const part of parts) {
          yield part;
        }
      }

      if (buffer.length > 0) {
        yield buffer;
      }
    },
    cancel() {
      this.input_text = "";
      this.loading = false;
      this.send_enable = false;
      this.style_enable = "margin-bottom: 0px;";
    },
    check() {
      if (this.input_text.length > 0) {
        this.style_enable = "margin-bottom: 30px;";
        this.send_enable = true;
      }
      this.input_text = this.input_text.substring(0, this.limit);
    },
    scroll() {
      this.$refs.input_text.style.height = this.$refs.input_text.scrollHeight + 'px';
    },
    scroll_chat() {
      const container = this.$refs.input_text;
      container.scrollTop = container.scrollHeight;
    },
    isNonEmptyString(value) {
      return typeof value === 'string' && value.trim().length > 0;
    }
  }
};
</script>

<style>
@import '../styles/styles_llama.css';
</style>
