<template>
  <div class="container">
  <a href="https://chat.kamenit.rs"><img class="logo" src="../assets/logo.png"/></a>
  <h1>LagalBot</h1>
</div>
  <translation-form-llama></translation-form-llama>
  <div class="container">
    <small>LagalBot v1.0</small><br>
      <small>(RAG baziran na LLaMA 3.1 Chat 8B)</small><br>
      <small><a href="https://www.paragraf.rs/propisi_download/zakon_o_prekrsajima.pdf">Zakon o prekršajima Srbije</a></small><br>
      <small><a href="https://www.paragraf.rs/propisi_download/zakon_o_bezbednosti_saobracaja_na_putevima.pdf">Zakon o bezbednosti saobracaja na putevima Srbije</a></small>
</div>

</template>

<script>
import TranslationFormLlama from '../components/TranslationFormLlama.vue';

export default {
components: {
  TranslationFormLlama
},
created(){
      document.title = "LegalBotV1.0"
  }
};
</script>

<style>
@import '../styles/styles.css';
@import 'sweetalert2/dist/sweetalert2.min.css';
</style>
